import React from "react";
import { Box, Button } from "@mui/material";

const HomeAdmin = () => {
  return (
      <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
          }}
      >
      </Box>
  );
};

export default HomeAdmin;
